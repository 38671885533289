/* eslint-disable @next/next/no-html-link-for-pages */
import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import Logo from '@img/shared/Logo.svg';
export default function Footer() {
  return (
    <div className=" w-full text-white py-10 text-center">
      <div className="flex flex-col items-center justify-center ">
        <Image src={Logo} height="80" width="90" alt="BlhackTag" />
        <p className="text-2xl font-bold mt-2">Blhack srl</p>
        <div>
          <p className="text-sm mt-2">VAT IT03793170121 - Gallarate (VA), Italy</p>
          <p className="text-sm font-regular">Cap. Soc. €10.000 i.v.</p>
        </div>
        <div className="flex text-sm  text-accent font-regular">
          <div className=" hover:font-bold">
            <a href="/privacy">Privacy Policy</a>
          </div>
          <div className="text-accent font-regular mx-2">|</div>
          <div className="hover:font-bold">
            <Link href="/contract">Condizioni contrattuali</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
