import { useRouter } from 'next/router';

interface ButtonProps {
  text: string;
  type: string;
  to?: string;
  onClick?: () => void;
}

export default function Button({ text = '', type = 'black', to, onClick }: ButtonProps) {
  const router = useRouter();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    router.push(to);
  };

  let buttonStyle: string;

  if (type === 'black') {
    buttonStyle =
      'bg-black text-white hover:text-blhack hover:bg-white border border-black transition duration-200';
  }
  if (type === 'white') {
    buttonStyle =
      'text-black bg-white hover:bg-blhack hover:text-white border border-white transition duration-200';
  }
  if (type === 'outline-dark') {
    buttonStyle =
      'text-white bg-black border border-white hover:text-blhack hover:bg-white transition duration-200';
  }
  if (type === 'outline-light') {
    buttonStyle =
      'text-black bg-white border border-black hover:text-white hover:bg-black transition duration-200';
  }
  if (type === 'yellow') {
    buttonStyle =
      'text-black bg-[#FCEB1E] border border-[#FCEB1E] hover:text-white hover:bg-black font-bold transition duration-200 ';
  }

  return (
    <button
      onClick={to ? handleClick : onClick}
      className={`font-bold px-4 py-2 sm:px-6 sm:py-3 text-sm sm:text-base ${buttonStyle} rounded-md`}
    >
      {text}
    </button>
  );
}
