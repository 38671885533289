import NavBar from '@components/shared/Navbar';
import Footer from '@components/shared/Footer';
import Head from '@components/shared/Head';
type Props = {
  pathname: string;
  children: React.ReactNode;
};
export default function Layout({ pathname, children }: Props) {
  return (
    <div>
      <Head />
      <NavBar {...{ pathname }} />
      <main>{children}</main>
      <Footer />
    </div>
  );
}
