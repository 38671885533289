import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import Button from '@components/ui/button';
import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import {
  ShoppingCartIcon,
  TerminalIcon,
  MenuIcon,
  DeviceMobileIcon,
  XIcon,
  ShoppingBagIcon,
  BookOpenIcon,
} from '@heroicons/react/outline';

import { ChevronDownIcon } from '@heroicons/react/solid';
//TODO: HIDDEN FOR NOW
// const caseLinks = [
//   {
//     name: 'Case studies',
//     href: '/case-studies',
//     icon: BookOpenIcon,
//   },
// ];

const webLinks = [
  {
    name: 'Applicazioni',
    description:
      'Hai un’impresa innovativa e vuoi sviluppare un progetto web cloud? Web app, e-commerce, gestionale, sei nel posto giusto!',
    href: '/web-app',
    icon: TerminalIcon,
  },
];
const shopifyLinks = [
  {
    name: 'Shopify Store',
    description:
      'Le tue idee, il nostro codice: creiamo l’e-commerce su misura per te, cucito attorno al tuo business.',
    href: '/shopify-ecommerce',
    icon: ShoppingBagIcon,
  },
  {
    name: 'Shopify App',
    description: 'Quanto a app di Shopify non conosciamo rivali. Scopri i nostri ultimi rilasci!',
    href: '/shopify-app',
    icon: ShoppingCartIcon,
  },
];
const mobileLinks = [
  {
    name: 'Mobile App',
    description: 'Sei pronto a portare il tuo business su mobile? Great, sei nel posto giusto!',
    href: '/app-mobile',
    icon: DeviceMobileIcon,
  },
];
const allLinks = [...webLinks, ...mobileLinks, ...shopifyLinks];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function NavBar({ pathname }) {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    function updateActiveIndex() {
      let element = document.getElementById('showMenu');
      let bodyRect = document.body.getBoundingClientRect();

      let offset = bodyRect.top + element?.offsetHeight;
      if (offset < 0) {
        setShow(true);
      } else {
        setShow(false);
      }
    }
    updateActiveIndex();

    (window.addEventListener as (type: string, listener: (event: Event) => void) => void)(
      'resize',
      updateActiveIndex
    );
    (
      window.addEventListener as (
        type: string,
        listener: (event: Event) => void,
        options?: { passive?: boolean }
      ) => void
    )('scroll', updateActiveIndex, {
      passive: true,
    });

    return () => {
      (window.removeEventListener as (type: string, listener: (event: Event) => void) => void)(
        'resize',
        updateActiveIndex
      );
      (
        window.removeEventListener as (
          type: string,
          listener: (event: Event) => void,
          options?: { passive?: boolean }
        ) => void
      )('scroll', updateActiveIndex, {
        passive: true,
      });
    };
  }, [show, pathname]);

  return (
    <Popover
      className={`
         bg-black top-0 sticky transition-all duration-700  ${show ? 'opacity-100' : ' opacity-0'}`}
      style={{ zIndex: 1000 }}
    >
      {show ? (
        <>
          <div className="mx-auto">
            <div className="flex justify-between items-center py-4 space-x-10 px-5 max-w-7xl mx-auto">
              <div className="flex justify-start">
                <Link href="/">
                  <div className="relative w-[72px] h-[60px] cursor-pointer">
                    <Image src="/img/shared/Logo.png" layout="fill" alt="Blhack logo" />
                  </div>
                </Link>
              </div>
              <div className="lg:hidden ">
                <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-white focus:outline-none">
                  <span className="sr-only">Open menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>

              <Popover.Group as="nav" className="hidden items-baseline lg:flex space-x-10 ">
                {/* //TODO: HIDDEN FOR NOW */}
                {/* <Link href="/case-studies">
                  <p className="ml-3 text-base font-medium text-white hover:text-yellow cursor-pointer">
                    Case studies
                  </p>
                </Link> */}
                <GroupLinks title="Web" links={webLinks} />
                <GroupLinks title="Mobile" links={mobileLinks} />
                <GroupLinks title="E-commerce" links={shopifyLinks} />
              </Popover.Group>
              <div className="items-center justify-self-end  hidden lg:flex ">
                <Button to="#contact" type="yellow" text="Get in touch" />
              </div>
            </div>
          </div>

          <Transition
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="z-50 absolute top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden"
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-black divide-y-2 divide-gray-50">
                <div className="pt-5 pb-6 px-5">
                  <div className="flex items-center justify-between">
                    <Link href="/">
                      <div className="relative w-[72px] md:w-[82px] h-[60px] md:h-[70px] cursor-pointer">
                        <Image src="/img/shared/Logo.png" layout="fill" alt="Blhack logo" />
                      </div>
                    </Link>
                    <div className="-mr-2 ">
                      <Popover.Button className="p-2 inline-flex items-center justify-center text-white focus:outline-none">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="mt-10">
                    <nav className="grid gap-y-8">
                      {allLinks.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="-m-3 p-3 flex items-center rounded-md hover:bg-[#FCEB1E] text-black group"
                        >
                          <item.icon
                            className="flex-shrink-0 h-6 w-6 text-white group-hover:text-black"
                            aria-hidden="true"
                          />
                          <span className="ml-3 text-base font-medium text-white group-hover:text-black">
                            {item.name}
                          </span>
                        </a>
                      ))}
                    </nav>
                  </div>
                </div>
                <div className="py-6 px-5 space-y-6">
                  <Button type="yellow" text="Get in touch" to="#contact" onClick={() => {}} />
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      ) : null}
    </Popover>
  );
}

function GroupLinks({ title, links }) {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              'group inline-flex items-center text-sm font-medium focus:outline-none text-white'
            )}
          >
            <span>{title}</span>
            <ChevronDownIcon
              className={classNames(
                open ? 'text-yellow' : 'text-white',
                'ml-2 h-5 w-5 group-hover:text-yellow'
              )}
              aria-hidden="true"
            />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                  {links.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                    >
                      <item.icon className="flex-shrink-0 h-6 w-6 text-black" aria-hidden="true" />
                      <div className="ml-4">
                        <p className="text-base font-medium text-gray-900">{item.name}</p>
                        <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
