import '../styles/globals.css';
import { useEffect } from 'react';
import type { AppProps } from 'next/app';
import Layout from '@components/layout/Layout';
import { useRouter } from 'next/router';
import TagManager from 'react-gtm-module';
import * as ga from '../lib/google';
import { motion } from 'framer-motion';
function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  let { pathname } = router;

  useEffect(() => {
    TagManager.initialize({ gtmId: `G-4BM2NMVXEB` });

    const handleRouteChange = (url) => {
      ga.pageview(url);
    };
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <motion.div
      key={router.route}
      initial="pageInitial"
      animate="pageAnimate"
      exit="pageExit"
      variants={{
        pageInitial: {
          background: 'black',
          opacity: 0,
        },
        pageAnimate: {
          background: 'black',
          transition: { duration: 0.8, type: 'ease-in', delay: 0.8 },
          opacity: 1,
        },
        pageExit: {
          background: 'black',
          opacity: 0,
        },
      }}
    >
      <Layout {...{ pathname }}>
        <Component {...pageProps} />
      </Layout>
    </motion.div>
  );
}

export default MyApp;
